import React from 'react'
import { Link } from 'gatsby'

import './index.css'

function ResourceBook({ children, url }) {
  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, url })
  )

  return <div className="resource-book">{childs}</div>
}

ResourceBook.Title = function ResourceBookTitle({ children, url }) {
  return (
    <div className="resource-book-title mt-24">
      <h4>
        <Link to={url}>{children}</Link>
      </h4>
    </div>
  )
}

ResourceBook.Footer = function ResourceBookFooter({ children }) {
  return <footer className="mt-24">{children}</footer>
}

export default ResourceBook
