import React, { useMemo, useCallback } from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'

import makeSlug from '@u/make-slug'

import withLocation from '../../../withLocation'
import QuickSearch from '@c/quick-search'
import Pagination from '@c/pagination'
import quickSearchBuilder, {
  QuickSearchMapping
} from '@c/quick-search/quick-search-builder'

import usePaginator from '@/hooks/resource/use-paginator'
import classNames from 'classnames'

import get from 'lodash/get'
import map from 'lodash/map'
import ResourceBook from '@c/resource-book'
import Book from '@c/book'
import Selectable from '@/components/selectable'
import { Link } from 'gatsby'
import addPageNumberToPage from '../add-page-number-to-page'
import addCategoryTitleToPage from '../add-category-title-to-page'

const Page = ({ data, pageContext }) => {
  const { pageSettings } = data
  const {
    resourcesSlug,
    resourcesTitle,
    resourcesBookSlug,
    resourcesBookTitle,
    _allResourcesSlugLocales,
    _allResourcesBookSlugLocales,
    categorySlug
  } = pageSettings
  const title = pageSettings.resourcesBookTitle
  const locale = pageContext.locale

  // Prepare the paginator
  const paginator = usePaginator({
    currentPage: pageContext.currentPage,
    totalPages: pageContext.numPages
  })
  // Map books from graphql result
  const books = useMemo(() => data.allDatoCmsBook.nodes)
  // Filter categories that doesnt have any books
  const categories = data.categories.nodes.filter(o =>
    data.usedCategories.distinct.includes(o.slug)
  )
  // Check if this list is categorized
  const currentCategory =
    (pageContext?.category &&
      categories.find(o => o.slug === pageContext?.category)) ||
    null
  // Set the text of category selectable
  const categoryText = currentCategory
    ? currentCategory.title
    : data?.datoCmsTranslation?.filteringAll
  // Create breadcrumb base
  const parentPages = [
    {
      title: resourcesTitle,
      url: makeSlug.generic(pageContext.locale, resourcesSlug)
    }
  ]

  const genSlug = useCallback(
    function genSlug(slug) {
      return makeSlug.generic(
        pageContext.locale,
        resourcesSlug,
        resourcesBookSlug,
        slug
      )
    },
    [pageContext, resourcesSlug, resourcesBookSlug]
  )
  const paginatorSlug = genSlug(
    currentCategory ? `${categorySlug}/${currentCategory.slug}` : ''
  )
  // If this is a categorized list then add uncategorized link as a breadcrumb parent
  if (currentCategory) {
    parentPages.push({
      title: resourcesBookTitle,
      url: genSlug('')
    })
  }

  // Prepare the search list for quick search
  const searchList = map(data.searchList.edges, 'node')
  const searchMappings = quickSearchBuilder()
    .addCriteria(
      new QuickSearchMapping(searchList, item =>
        makeSlug.generic(locale, resourcesSlug, resourcesBookSlug, item?.slug)
      )
    )
    .build()
  const fnCreateSeoMeta = () => {
    // Remove english page
    const locales = _allResourcesSlugLocales
      .map(o => o.locale)
      .filter(o => o !== 'en')
    const r = []
    const fnGetByLang = (allLocale, locale) =>
      allLocale?.find(o => o.locale === locale)?.value
    locales.forEach(element => {
      const slugChain = [
        fnGetByLang(_allResourcesSlugLocales, element),
        fnGetByLang(_allResourcesBookSlugLocales, element)
      ]
      r.push({
        rel: 'alternate',
        href: makeSlug.generic(element, ...slugChain),
        hreflang: element
      })
    })
    return {
      link: r,
      title: addPageNumberToPage(
        addCategoryTitleToPage(title, currentCategory),
        locale,
        paginator
      )
    }
  }

  return (
    <Layout
      locale={pageContext.locale}
      seo={{
        ...fnCreateSeoMeta()
      }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-40">
        <div className="row align-items-center">
          <div className="col-12 col-lg-auto">
            <Breadcrumb
              className={currentCategory ? '' : 'resource-breadcrumb'}
              parentPages={parentPages}
              currentPage={currentCategory?.title || title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-lg-4" style={{ marginBottom: '16px' }}>
            <Selectable text={categoryText} style={{ zIndex: 2 }}>
              <Link to={genSlug('')}>
                {data?.datoCmsTranslation?.filteringAll}
              </Link>
              {categories.map((o, key) => (
                <Link key={key} to={genSlug(`${categorySlug}/${o.slug}`)}>
                  {o.title}
                </Link>
              ))}
            </Selectable>
          </div>
          <div
            className={classNames(
              'col-12 d-flex flex-column align-items-center',
              paginator.result.totalPages > 1 ? 'col-lg-auto' : 'col-lg-4'
            )}
          >
            <QuickSearch
              mappings={searchMappings}
              style={{ marginBottom: '16px', zIndex: 1, minWidth: 'auto' }}
            />
            <Pagination paginator={paginator} baseSlug={paginatorSlug} />
          </div>
        </div>
        <div className="d-flex justify-content-center justify-content-lg-end"></div>
      </div>
      {/* ALL */}
      <div className="container">
        <div className="row mt-40">
          {books.map(book => (
            <div key={book.id} className="col-12 col-lg-4 mb-60">
              <ResourceBook
                url={makeSlug.generic(
                  book.locale,
                  resourcesSlug,
                  resourcesBookSlug,
                  book.slug
                )}
              >
                <Book
                  photo={get(book, 'coverImage')}
                  alt={get(book, 'coverImage.alt') || ''}
                  title={book.coverTitleFirst}
                  titleSecond={book.coverTitleSecond}
                  coverDescription={book.coverDescription}
                />
                <ResourceBook.Title>{book.title}</ResourceBook.Title>
              </ResourceBook>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="d-flex flex-column align-items-center">
          <Pagination paginator={paginator} baseSlug={paginatorSlug} />
        </div>
      </div>
    </Layout>
  )
}

export default withLocation(Page)

export const query = graphql`
  query(
    $locale: String
    $skip: Int!
    $limit: Int!
    $filter: DatoCmsBookFilterInput!
  ) {
    datoCmsTranslation(locale: { eq: $locale }) {
      filteringAll
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        _allSlugLocales {
          locale
          value
        }
      }
    }
    usedCategories: allDatoCmsBook(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      distinct(field: categories___slug)
    }
    searchList: allDatoCmsBook(
      filter: { locale: { eq: $locale }, slug: { regex: "/.+/" } }
    ) {
      edges {
        node {
          _allSlugLocales {
            locale
            value
          }
          title
          slug
          locale
        }
      }
    }
    allDatoCmsBook(
      filter: $filter
      limit: $limit
      skip: $skip
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      nodes {
        ...DatoCmsBookListItem
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesBookSlugLocales {
        locale
        value
      }
      _allCategorySlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesBookTitle
      resourcesBookSlug
      categorySlug
    }
  }
`
